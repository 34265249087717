import React from "react"
import styled from "@emotion/styled"

import { baseButtonStyles } from "../Button"

import CarouselNavIcon from "../../svgs/CarouselNavIcon"

import { mediaQueries } from "../../styles"

type Props = {
  isNext?: boolean
}

const Button = styled.button<Props>`
  ${baseButtonStyles}
  padding: 0;
  min-width: 0;
  border: none;
  transform: ${props => (props.isNext ? `rotate(0.5turn)` : null)};

  ${mediaQueries.max576}{
    width: 33px;
    height: 33px;

    svg {
      width: 33px;
      height: 33px;
    }
  }

  circle,
  path {
    transition: stroke 0.12s ease-in;
  }

  &:hover {
    background: none;

    circle,
    path {
      stroke: var(--secondary);
    }
  }
`

const NavButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & Props
> = props => {
  return (
    <Button {...props}>
      <CarouselNavIcon />
    </Button>
  )
}

export default NavButton
