import { useRef, useEffect } from "react"
import Button from "../Button"
import { ProductCompact as ProductCompactType } from "../../types/Product"

interface Props {
  title: string
  variant?: "primary" | "primary-filled" | "secondary" | "outline-white"
  selectedProductVariant?: string
  className?: string
  product: ProductCompactType
  qty?: number
  enableSnipcart?: boolean
}

const BuyButton = ({
  title,
  variant,
  selectedProductVariant,
  className,
  product,
  qty,
  enableSnipcart = false,
  ...props
}: Props) => {
  const variantsString = useRef<string | null>(null)

  const variantsSnipcartAttrs = () => {
    if (product?.variants?.length) {
      return {
        "data-item-custom1-name": "Variant",
        "data-item-custom1-options": variantsString.current,
        "data-item-custom1-value": selectedProductVariant,
      }
    }
  }

  useEffect(() => {
    if (product?.variants?.length) {
      variantsString.current = product?.variants
        .map((v, index, arr) => {
          let price = 0
          let priceSign: string = ""
          if (v?.price) {
            price = v?.price - product.price
            if (price > 0) {
              priceSign = "+" // add remainder
            }
          }
          const pipe: string = index < arr.length - 1 ? "|" : ""
          return price !== 0
            ? `${v.name}[${priceSign}${price}]${pipe}`
            : `${v.name}${pipe}` // don't include price
        })
        .join("")
    }
  }, [product.variants, product.price])

  // fix this as it can be more than to digits + a string with non-numeric chars
  // const weightNumber = product.weight.slice(0, 2)

  return (
    <Button
      variant={variant || "primary"}
      type="button"
      className={`${enableSnipcart ? "snipcart-add-item" : ""} ${className}`}
      data-item-id={product.id}
      data-item-price={product.price}
      data-item-name={product.name}
      data-item-image={product.photo?.gatsbyImageData.images.fallback?.src}
      data-item-url={`https://casabi.netlify.app/${product.slug}`}
      data-item-payment-interval={
        product.isSubscription && product.subscriptionInterval
      }
      data-item-payment-interval-count="1"
      data-item-weight={0}
      data-item-shippable={product.isPhysical}
      // Define if qty will be managed in Snipcart or DatoCMS and fetch & update data from there
      data-item-quantity={qty}
      // data-item-max-quantity={maxQty}
      {...variantsSnipcartAttrs()}
      {...props}
    >
      {title}
    </Button>
  )
}

export default BuyButton
