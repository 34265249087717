import { useState, useEffect } from "react"
import { PageProps, graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { marked } from "marked"
import insane from "insane"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import ProductsCarousel from "../../components/ProductsCarousel"

import {
  Carousel,
  ProductName,
  ProductWeight,
  ProductPrice,
  ProductDetails,
  Variants,
  StyledBuyButton,
  VariantInput,
  VariantLabel,
  ButtonGroup,
  QtyButton,
  Decrement,
  Qty,
  Increment,
  ProductImage,
  RelatedProductsSection,
  ProductContents,
  MainSection,
  StickyWrapper,
  StyledNavButton,
} from "./styles"

import { Product } from "../../types/Product"
import useSiteMetadata from "../../queries/useSiteMetadata"

const ProductTemplate = ({ data }: PageProps<{ product: Product }>) => {
  const [selectedVariant, setSelectedVariant] = useState(
    data.product?.variants[0]?.name,
  )
  const [qty, setQty] = useState(1)
  const [maxQty, setMaxQty] = useState(10)

  const { siteUrl } = useSiteMetadata()

  const { t } = useTranslation("", { keyPrefix: "productItem" })

  useEffect(() => {
    // call snipcart api to fetch available qty
    setMaxQty(15)
  }, [])

  const { product } = data

  const handleDecrement = () => setQty(qty - 1 < 0 ? qty : qty - 1)
  const handleIncrement = () => setQty(qty + 1 > maxQty ? qty : qty + 1)

  const calculatePrice = () => {
    let calculatedPrice = product.price * qty
    if (data.product?.variants) {
      const sVariant = data.product?.variants.find(v => v.name === selectedVariant)
      if (sVariant?.price) {
        calculatedPrice = sVariant?.price * qty
      }
    }
    return calculatedPrice
  }

  return (
    <Layout>
      <SEO
        page={{
          title: `${data.product.name} - Casabi`,
          url: `${siteUrl}/${data.product.slug}`,
        }}
      />
      <MainSection>
        <Container>
          <Row>
            <Col md={6} lg={8} className="col-xxl-8">
              <StickyWrapper>
                <Carousel
                  slidesToShow={1}
                  slidesToScroll={1}
                  heightMode="max"
                  wrapAround
                  renderBottomCenterControls={null}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <StyledNavButton placement="left" onClick={previousSlide} />
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <StyledNavButton
                      placement="right"
                      onClick={nextSlide}
                      isNext={true}
                    />
                  )}
                >
                  {product.gallery.map(photo => (
                    <ProductImage
                      key={photo.originalId}
                      image={photo.gatsbyImageData}
                      alt={photo.title || photo.alt || product.name}
                      imgStyle={{ objectFit: `contain` }}
                    />
                  ))}
                </Carousel>
              </StickyWrapper>
            </Col>
            <Col md={6} lg={4}>
              <div>
                <ProductName>{product.name}</ProductName>
                <ProductWeight>{product.weight}</ProductWeight>

                <div className="d-flex">
                  <ProductContents>{product.contents}</ProductContents>
                </div>

                <ProductPrice>RD${calculatePrice()}</ProductPrice>

                <ButtonGroup>
                  <QtyButton>
                    <Decrement onClick={handleDecrement} disabled={qty === 1}>
                      -
                    </Decrement>
                    <Qty>{qty}</Qty>
                    <Increment onClick={handleIncrement} disabled={qty === maxQty}>
                      +
                    </Increment>
                  </QtyButton>

                  <StyledBuyButton
                    variant="primary-filled"
                    title={t("addButton")}
                    selectedProductVariant={selectedVariant}
                    product={{ ...product }}
                    qty={qty}
                    enableSnipcart
                  />
                </ButtonGroup>

                <Variants>
                  {product.variants.map(variant => (
                    <VariantLabel key={variant.name}>
                      <VariantInput
                        type="radio"
                        name="variant"
                        value={variant.name}
                        key={variant.name}
                        onChange={() => setSelectedVariant(variant.name)}
                        checked={selectedVariant === variant.name}
                      />
                      {variant.name}
                    </VariantLabel>
                  ))}
                </Variants>

                <ProductDetails
                  dangerouslySetInnerHTML={{
                    __html: marked.parse(insane(product.details)),
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </MainSection>
      <RelatedProductsSection>
        <Container>
          <h3>{t("moreProducts")}</h3>
          <ProductsCarousel products={product.relatedProducts} />
        </Container>
      </RelatedProductsSection>
    </Layout>
  )
}

export default ProductTemplate

export const productQuery = graphql`
  query ($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    product: datoCmsProduct(slug: { eq: $slug }, locale: { eq: $language }) {
      id
      name
      price
      weight
      slug
      seoMetaTags {
        tags
      }
      contents
      variants {
        name
        price
      }
      details
      photo {
        alt
        title
        originalId
        gatsbyImageData(width: 320)
      }
      gallery {
        gatsbyImageData(width: 1200)
      }
      relatedProducts {
        id
        name
        slug
        weight
        price
        photo {
          alt
          title
          gatsbyImageData(width: 400)
        }
      }
    }
  }
`
