import React from "react"

const CarouselNavIcon: React.FC = props => (
  <svg width={44} height={44} viewBox="0 0 44 44" fill="none" {...props}>
    <circle
      cx={22}
      cy={22}
      r={21.5}
      stroke="#89C16D"
    />
    <path d="M26.25 12.474L15 22l11.25 9.526" stroke="#89C16D" />
  </svg>
)

export default CarouselNavIcon
