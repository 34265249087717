import React from "react"
import { css } from "@emotion/react"

import useWindowSize from "../../hooks/useWindowSize"

import Carousel from "nuka-carousel"

import NavButton from "./NavButton"

import { breakpoints, mediaQueries } from "../../styles"

const isMobile = (width: number | undefined) =>
  Boolean(width && width <= breakpoints.md)

interface Props {
  children: React.ReactNode
}

const ProductsCarousel: React.FC<Props> = ({ children, ...props }) => {
  const { width } = useWindowSize()

  return (
    <Carousel
      slidesToShow={isMobile(width) ? 1 : 3}
      slidesToScroll={isMobile(width) ? 1 : 3}
      heightMode="max"
      renderBottomCenterControls={null}
      cellSpacing={32}
      framePadding="32px"
      renderCenterLeftControls={({ previousSlide }) => (
        <NavButton onClick={previousSlide} />
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <NavButton onClick={nextSlide} isNext={true} />
      )}
      className="products-carousel"
      css={styles}
      wrapAround
      {...props}
    >
      {children}
    </Carousel>
  )
}

export default ProductsCarousel

const styles = css`
  &:focus {
    outline: none;
  }

  ${mediaQueries.max576} {
    .slider-control-centerleft {
      top: 25% !important;
      transform: translateX(-50%) !important;
    }

    .slider-control-centerright {
      top: 25% !important;
      transform: translateX(50%) !important;
    }
  }

  ${mediaQueries.min992} {
    .slider-control-centerleft {
      transform: translate(-100%, -50%) !important;
    }

    .slider-control-centerright {
      transform: translate(100%, -50%) !important;
    }
  }
`
